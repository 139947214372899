import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";

// Constants outside the styled function to prevent unnecessary recomputation
const configuratorWidth = 360;
const drawerCloseOffset = -350;

// Optimized styles for the drawer when openConfigurator is true
const drawerOpenStyles = (transitions, pxToRem) => ({
  width: configuratorWidth,
  left: "initial",
  right: 0,
  transition: transitions.create("right", {
    easing: transitions.easing.sharp,
    duration: transitions.duration.short,
  }),
});

// Optimized styles for the drawer when openConfigurator is false
const drawerCloseStyles = (transitions, pxToRem) => ({
  left: "initial",
  right: pxToRem(drawerCloseOffset),
  transition: transitions.create("all", {
    easing: transitions.easing.sharp,
    duration: transitions.duration.short,
  }),
});

export default styled(Drawer)(({ theme, ownerState }) => {
  // Destructure theme properties once
  const { boxShadows, functions, transitions } = theme;
  const { lg } = boxShadows;
  const { pxToRem } = functions;

  // Determine styles based on openConfigurator state
  const styles = ownerState.openConfigurator
    ? drawerOpenStyles(transitions, pxToRem)
    : drawerCloseStyles(transitions, pxToRem);

  return {
    "& .MuiDrawer-paper": {
      height: "100vh",
      margin: 0,
      padding: `0 ${pxToRem(10)}`,
      borderRadius: 0,
      boxShadow: lg,
      overflowY: "auto",
      ...styles, // Apply memoized styles based on state
    },
  };
});
