import React, { createContext, useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { initialState, chatReducer } from '../logics/ChatLogic';
import { } from "../constants/ChatConstants"
import { AuthContext } from "./index"
import { useNavigate } from "react-router-dom";




// Create a Context
export const CallContext = createContext();

// Create a Provider component
const CallContextProvider = ({ children }) => {
  const navigate = useNavigate()
  const [incommingCall, setIncommingCall] = useState(null)
  const [roomId, setRoomId] = useState(null)
  const [callContinue, setCallContinue] = useState(false)
  const [callType,setCallType] = useState('audio')

  const { socket, getCurrentUser } = useContext(AuthContext);
  const [state, dispatch] = useReducer(chatReducer, initialState);
  const { id = null, name = '' } = getCurrentUser() || {};
  const AudioRef = useRef(null)


  const startCall = async (targetUserId, callType) => {
    if (socket) {
      const roomId = `${id}to${targetUserId}`
      setRoomId(roomId)
      setCallType(callType)
      socket.emit('user:Call', { from: id, to: targetUserId, roomId, callType });
    }
    setCallContinue(true)
    navigate('/meeting')
  }

  useEffect(() => {
    if (socket) {
      socket.on('incomming:Call', handleReceiveCall);
      socket.on('Call:accepted', handleAudioCallAccepted);
    }
    return () => {
      if (socket) {
        socket.off('incomming:Call', handleReceiveCall);
        socket.off('Call:accepted', handleAudioCallAccepted);
      }
    };
  }, [socket]);

  const handleReceiveCall = useCallback(async ({ from, roomId, callType }) => {
    setRoomId(roomId)
    setCallType(callType)
    if (AudioRef.current) {
      AudioRef?.current?.play()
    }
    setIncommingCall({ ...from })
  }, [socket, AudioRef]);


  const handleAudioCallAccepted = useCallback(async () => {
  }, []);

  const AcceptAudioCall = async () => {
    setCallContinue(true)
    navigate('/meeting')
  }
  const RejectAudioCall = useCallback(async () => {
    if (AudioRef.current) {
      AudioRef?.current?.pause()
      AudioRef.current.currentTime = 0;
    }
    setIncommingCall(null)
    setRoomId(null)
    setCallType(null)
    setCallContinue(false)
    if (socket) {
      socket.emit('Call:rejected', { to: incommingCall?.id, roomId })
    }
  }, [socket])


  const CallEnded = useCallback(async () => {
    setIncommingCall(null)
    setRoomId(null)
    setCallType(null)
    setCallContinue(false)
    navigate('/chat')
  }, [])



  return (
    <CallContext.Provider value={{ state, dispatch, socket, startCall, AudioRef, roomId, AcceptAudioCall, RejectAudioCall, incommingCall, callContinue,callType, CallEnded }}>
      {children}
    </CallContext.Provider>
  );
};
export default CallContextProvider;
