import HttpService from "./http.service";

class UtilService {
  fetchTeems = async (payload) => {
    const Endpoint = "/util/fetch/teams";
    return await HttpService.post(Endpoint, payload);
  };

  getCounts = async (payload) => {
    const Endpoint = "/util/counts";
    return await HttpService.post(Endpoint, payload);
  };

  addAdmin = async (payload) => {
    const Endpoint = "/util/add/admin";
    return await HttpService.post(Endpoint, payload);
  };

  getAdmins = async () => {
    const Endpoint = "/util/admins"; 
    const response = await HttpService.get(Endpoint);
    return response.data; 
  };

  updateAdmin = async (userId, payload) => {
    const Endpoint = `/util/update/admin/${userId}`;
    return await HttpService.put(Endpoint, payload);
  };

  deleteAdmin = async (userId) => {
    const Endpoint = `/util/delete/admin/${userId}`;
    return await HttpService.delete(Endpoint);
  };

}

export default new UtilService();