import HttpService from "./http.service";

class PrizeService {
  createPrize = async (payload) => {
    const Endpoint = "/prize/create";
    return await HttpService.post(Endpoint, payload);
  };
  getPrize = async (search) => {
    const Endpoint = `/prize/fetch/${search}`;
    return await HttpService.get(Endpoint);
  };
  getPrizeById = async (id) => {
    const Endpoint = `/prize/fetchById/${id}`;
    return await HttpService.get(Endpoint);
  };
  updatePrize = async (id, payload) => {
    const Endpoint = `/prize/update/${id}`;
    return await HttpService.put(Endpoint, payload);
  };
  deletePrize = async (id) => {
    const Endpoint = `/prize/delete/${id}`;
    return await HttpService.delete(Endpoint);
  };
}

export default new PrizeService();