export const SET_LOADING = "SET_LOADING"
export const SET_USERS = "SET_USERS"
export const SET_GROUPS = "SET_GROUPS"
export const SET_MEMBERS = "SET_MEMBERS"
export const SET_MESSAGES = "SET_MESSAGES"
export const SET_MESSAGE = "SET_MESSAGE"
export const ADD_MESSAGE = "ADD_MESSAGE"
export const SET_SEARCH = "SET_SEARCH"
export const SET_MEMBERS_SEARCH = "SET_MEMBERS_SEARCH"
export const SET_SELECTED_USER = "SET_SELECTED_USER"
export const SET_SELECTED_GROUP = "SET_SELECTED_GROUP"
export const SET_UNREAD_MESSAGES_COUNT = "SET_UNREAD_MESSAGES_COUNT"
export const SET_OPEN_WEB_CAMERA = "SET_OPEN_WEB_CAMERA"
export const SET_IMAGE_TO_UPLOAD = "SET_IMAGE_TO_UPLOAD"