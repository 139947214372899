import {
  SET_LOADING,
  SET_USERS, SET_MEMBERS, SET_SEARCH,
  SET_MEMBERS_SEARCH, SET_SELECTED_USER,
  SET_MESSAGES, ADD_MESSAGE, SET_GROUPS,
  SET_SELECTED_GROUP, SET_UNREAD_MESSAGES_COUNT,
  SET_OPEN_WEB_CAMERA, SET_IMAGE_TO_UPLOAD
} from "../constants/ChatConstants"

const initialState = {
  loading: false,
  users: [],
  members: [],
  messages: [],
  search: "",
  membersSearchQuery: "",
  selectedUser: null,
  groups: [],
  selectedGroup: null,
  unreadMessagesCount: 0,
  webCamera: false,
  selectedImage: null
};

const chatReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_USERS:
      return {
        ...state,
        users: action.payload,
        loading:false
      };
    case SET_MEMBERS:
      return {
        ...state,
        members: action.payload,
        loading:false
      };
    case SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.payload,
        selectedGroup: null,
        loading:true 
      };
    case SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case SET_MEMBERS_SEARCH:
      return {
        ...state,
        membersSearchQuery: action.payload,
        loading:false
      };
    case SET_MESSAGES:
      return {
        ...state,
        messages: action.payload,
        loading:false
      };
    case ADD_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload],
        loading:false
      };
    case SET_GROUPS:
      return {
        ...state,
        groups: action.payload,
        loading:false
      };
    case SET_SELECTED_GROUP:
      return {
        ...state,
        selectedGroup: action.payload,
        selectedUser: null,
        loading:true
      };
    case SET_UNREAD_MESSAGES_COUNT:
      return {
        ...state,
        unreadMessagesCount: action.payload,
        loading:false
      };
    case SET_OPEN_WEB_CAMERA:
      return {
        ...state,
        webCamera: action.payload,
        loading:false
      };
    case SET_IMAGE_TO_UPLOAD:
      return {
        ...state,
        selectedImage: action.payload,
        loading:false
      };
    default:
      return state;
  }
};

export { initialState, chatReducer };


export function dataURLtoBlob(dataurl) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export function blobToFile(theBlob, fileName) {
  // A Blob is just like a File but without the two properties below
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
}