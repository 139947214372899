import React, { createContext, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { initialState, chatReducer } from '../logics/ChatLogic';
import { SET_LOADING, SET_USERS, SET_MEMBERS, SET_MESSAGES, SET_SELECTED_USER, ADD_MESSAGE, SET_GROUPS, SET_SELECTED_GROUP, SET_UNREAD_MESSAGES_COUNT } from "../constants/ChatConstants"
import { AuthContext } from "./index"
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import useTranslation from 'hooks/useTranslation';

// Create a Context
export const ChatContext = createContext();

// Create a Provider component
const ChatContextProvider = ({ children }) => {
    const messageEndRef = useRef(null);
    const [scrollHeight, setScrollHeight] = useState(0)

    const { t } = useTranslation();
    const { socket, getCurrentUser } = useContext(AuthContext);
    const [state, dispatch] = useReducer(chatReducer, initialState);
    const { role, id: user_id } = getCurrentUser?.() || {};

    const sendToAllButtonEnabled = useMemo(() => role === 3, [role])

    useEffect(() => {
        if (socket && user_id) {
            socket.emit("get_users", { user_id })
            socket.emit("fetch_user_groups", user_id)
            socket.emit("get_unread_message_count", { userId: user_id });
        }
    }, [socket, user_id])
    useEffect(() => {
        if (socket) {
            const searchPayload = {
                user_id,
                search: state.search,
                ...(state.membersSearchQuery && { membersSearchQuery: state.membersSearchQuery })
            };
            socket.emit(state.search?.length || state.membersSearchQuery?.length ? "find_users" : 'get_users', searchPayload);
        }
    }, [state.search, state.membersSearchQuery, socket, user_id]);

    useEffect(() => {
        // Listen for updates via Socket.IO
        const handleUserListUpdated = (data) => {
            dispatch({ type: SET_USERS, payload: data });
        };

        if (socket) {
            socket.on('user_list_updated', handleUserListUpdated);
        }
        if (socket) {
            socket.on('members_list_updated', (data) => {
                dispatch({ type: SET_MEMBERS, payload: data });
            });
        }

        return () => {
            if (socket) {
                socket.off('user_list_updated', handleUserListUpdated);
            }
        };
    }, [dispatch, socket]);


    useEffect(() => {
        if (state?.selectedUser?.userId) {
            // Fetch initial messages for the selected user
            if (socket) {
                dispatch({
                    type: SET_LOADING,
                })
                socket.emit("get_messages", { userId: user_id, recieverId: state.selectedUser?.userId });
                socket.on("messages", (messages) => {
                    dispatch({
                        type: SET_MESSAGES,
                        payload: messages
                    })
                    // Scroll to the bottom of the message list when new messages are added
                    // messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
                });
            }
            // Cleanup socket connection on unmount
            return () => {
                if (socket) {
                    socket.off("get_messages");
                    socket.off("messages");
                }
            };
        }
    }, [state?.selectedUser, socket]);

    useEffect(() => {
        if (socket) {
            socket.emit("get_unread_message_count", { userId: user_id });
        }
    }, [state?.messages])


    useEffect(() => {
        if (state?.selectedGroup?.id) {
            // Fetch initial messages for the selected user
            if (socket) {
                socket.emit("get_group_messages", { groupId: state?.selectedGroup?.id });

            }
        }
    }, [state?.selectedGroup, socket]);

    useEffect(() => {
        if (messageEndRef.current) {
            if(messageEndRef.current.getScrollElement().scrollHeight !== scrollHeight){
                //setTimeout(() => {
                    setScrollHeight(messageEndRef.current.getScrollElement().scrollHeight)
                    messageEndRef.current.getScrollElement().scrollTo({
                        top: messageEndRef.current.getScrollElement().scrollHeight,
                    });
                //  }, 0);
            }
        }
    }, [state.messages])

    const CustomToastWithLink = ({ id, name, group = false }) => {
        return (
            <div>
                <Link to={`/chat?sender_id=${id}`}>{t("A New Message Recieved")} {group ? t('in') : t('from')} {name}</Link>
            </div>
        )
    };


    useEffect(() => {
        if (socket) {
            socket.on("message_sent", (message) => {
                dispatch({
                    type: ADD_MESSAGE,
                    payload: message
                })
            });
            socket.on("message_received", (message) => {
                toast.info(CustomToastWithLink({ id: message.sender_id, name: message.sender_name }));
                if(state?.selectedUser?.userId == message.sender_id){
                    socket.emit("get_users", { user_id })
                    dispatch({
                        type: ADD_MESSAGE,
                        payload: message
                    })
                    // dispatch({
                    //     type: SET_SELECTED_USER,
                    //     payload: { userId: Number(message.sender_id), userName: message.sender_name }
                    // })
                }
            });

            socket.on("usersUpdated", () => {
                socket.emit("get_users", { user_id })
            });
            socket.on("unread_messages_count_update", (data) => {
                dispatch({
                    type: SET_UNREAD_MESSAGES_COUNT,
                    payload: data
                })
            });

            socket.on("messageRead", (message) => {
                socket.emit("get_users", { user_id })
                if (state.selectedUser?.userId) {
                    socket.emit("get_messages", { userId: user_id, recieverId: state.selectedUser?.userId });
                }
            });

            socket.on("groupCreated", () => {
                socket.emit('fetch_user_groups', user_id)
            });
            socket.on("groupCreatedSuccess", () => {
                socket.emit('fetch_user_groups', user_id)
            });
            socket.on("userGroups", (data) => {
                const group = data.find(item => item.id === state?.selectedGroup?.id)
                // console.log(group,state?.selectedGroup?.id)
                if (group) {
                    dispatch({
                        type: SET_SELECTED_GROUP,
                        payload: group
                    })
                }
                dispatch({
                    type: SET_GROUPS,
                    payload: data,
                })
            });
            socket.on("group_messages", (messages) => {
                dispatch({
                    type: SET_MESSAGES,
                    payload: messages
                })
            });
            socket.on("newGroupMessage", (message) => {
                if (message.sender_id !== user_id) {
                    toast.info(CustomToastWithLink({ id: message.group_id, name: message.group_name, group: true }));
                }
                dispatch({
                    type: ADD_MESSAGE,
                    payload: message
                })
            });
            socket.on("error", (message) => {
                toast.error(message);
            });

            socket.on("messageReadStatus", (message) => {
                socket.emit("get_unread_message_count", { userId: user_id })
                if (message.msgId) {
                    dispatch({
                        type: SET_MESSAGES,
                        payload: state.messages.map((msg) =>
                            msg.id === message.msgId ? { ...msg, is_read: true } : msg
                        ),
                    })
                }
            });
            socket.on("groupUpdated", () => {
                socket.emit('fetch_user_groups', user_id)
            });
            socket.on("removedFromGroup", (groupId) => {
                const group = state?.groups.find(item => item.id === groupId)
                socket.emit('fetch_user_groups', user_id)
                toast.info(`${t("you are removed from")} ${group.name}`)
                dispatch({
                    type: SET_SELECTED_GROUP,
                    payload: null
                })
            });
            socket.on("userAddedToGroup", ({ groupName, userName }) => {
                socket.emit('fetch_user_groups', user_id)
                toast.info(`${t("you added")} ${userName} ${t("to")} ${groupName}`)
            });
            socket.on("addedToGroup", ({ groupName }) => {
                socket.emit('fetch_user_groups', user_id)
                toast.info(`${t("you are added to")} ${groupName}`)
            });
            socket.on("groupDeleted", ({ groupName }) => {
                socket.emit('fetch_user_groups', user_id)
                dispatch({
                    type: SET_SELECTED_GROUP,
                    payload: null
                })
                toast.info(`${t("you deleted group")} ${groupName} ${t("successfully")}`)
            });
            socket.on("removedUserFromGroup", ({ groupName }) => {
                socket.emit('fetch_user_groups', user_id)
                toast.info(`${t("you removed a user from")} ${groupName}`)
            });
            socket.on("notification", ({ message }) => {
                toast.info(message);
            });
            socket.on("chat_deleted", ({ message }) => {
                toast.info(message);
                socket.emit("get_users", { user_id })
                socket.emit("fetch_user_groups", user_id)
                socket.emit("get_unread_message_count", { userId: user_id });
                dispatch({
                    type: SET_SELECTED_USER,
                    payload: null
                })
            });
        }

        return () => {
            if (socket) {
                socket.off("message_sent");
                socket.off("message_received");
                socket.off("messageRead");
                socket.off("unread_messages_count_update");
                socket.off("usersUpdated");
                socket.off("groupCreated");
                socket.off("groupCreatedSuccess");
                socket.off("userGroups");
                socket.off("group_messages");
                socket.off("newGroupMessage");
                socket.off("messageReadStatus");
                socket.off("groupUpdated");
                socket.off("removedFromGroup");
                socket.off("userAddedToGroup");
                socket.off("addedToGroup");
                socket.off("groupDeleted");
                socket.off("removedUserFromGroup");
                socket.off("error");
                socket.off("notification");
                socket.off("chat_deleted");
            }
        }
    }, [state, socket])

    const setMessageRead = (msgId) => {
        socket.emit("markMessageAsRead", { msgId })
    }
    const createANewGroup = ({ groupName, groupDescription, groupMembers }) => {
        socket.emit("create_a_new_group", { groupName, groupDescription, members: groupMembers.map(item => item.id), creatorId: user_id })
    }
    const setGroupMessageRead = (msgId) => {
        socket.emit('mark_message_as_read', { messageId: msgId, userId: user_id });
    }
    const kickoutAmember = ({ userId, groupId }) => {
        socket.emit('remove_user_from_group', { userId, groupId, me: user_id });
    }
    const addAMemberToGroup = ({ userId, groupId }) => {
        socket.emit('add_user_to_group', { userId, groupId, me: user_id });
    }
    const deleteTheGroup = ({ userId, groupId }) => {
        socket.emit('delete_group', { userId, groupId, me: user_id });
    }
    const sendMessageToAllUsers = ({ messageText }) => {
        socket.emit('broadcast_message', {
            senderId: user_id,
            content: messageText,
            hasFile: false,
            fileUrl: null,
            fileType: null,
        });
    }
    const deleteUserChat = ({ userId, groupId }) => {
        socket.emit('delete_user_chat', { active_user_id: userId, opponent_user_id: groupId });
    }

    return (
        <ChatContext.Provider value={{
            state, dispatch, socket, setMessageRead,
            messageEndRef, createANewGroup, setGroupMessageRead, kickoutAmember,
            addAMemberToGroup, deleteTheGroup, sendToAllButtonEnabled, sendMessageToAllUsers,
            deleteUserChat
        }}>
            {children}
        </ChatContext.Provider>
    );
};
export default ChatContextProvider;
