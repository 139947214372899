import { AuthContext } from 'context';
import { useContext } from 'react';

const useTranslation = () => {
  const { language, changeLanguage, dictionary } = useContext(AuthContext);

  const t = (key) => dictionary[key] || key;

  return {
    language,
    changeLanguage,
    t,
  };
};

export default useTranslation;
