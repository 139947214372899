import HttpService from "./http.service";

class CollaboratorService {

  getMembers = async (payload) => {
    const Endpoint = `/collaborators/fetch`;
    return await HttpService.post(Endpoint, payload);
  };

  ChangeUser = async (payload) => {
    const Endpoint = `/collaborators/switch`;
    return await HttpService.post(Endpoint, payload);
  };

  ChangeChild = async (payload) => {
    const Endpoint = `/collaborators/switch/child`;
    return await HttpService.post(Endpoint, payload);
  };

  getUserIdByMasterId = async (payload) => {
    const Endpoint = `/collaborators/getUserId`;
    return await HttpService.post(Endpoint, payload);
  };

  getNewOrdersCount = async () => {
    const Endpoint = `/collaborators/orders-count`;
    return await HttpService.get(Endpoint);
  };

  sendEmailReminders = async (notRegisteredUsers) => {
    const Endpoint = `/collaborators/send-email-reminders`;
    return await HttpService.post(Endpoint, notRegisteredUsers);
  };

  sendSmsReminders = async (notRegisteredUsers) => {
    const Endpoint = `/collaborators/send-sms-reminders`;
    return await HttpService.post(Endpoint, notRegisteredUsers);
  };

}

export default new CollaboratorService();
