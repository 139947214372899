import { useEffect, useState, useContext, useCallback, useMemo } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavList from "examples/Sidenav/SidenavList";
import SidenavItem from "examples/Sidenav/SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  AuthContext,
} from "context";

import AuthService from "services/auth-service";
import { Can } from "Can";
import useTranslation from "hooks/useTranslation";
import MDInput from "components/MDInput";
import { MuiTelInput } from "mui-tel-input";
import Swal from "sweetalert2";
import inviteService from "services/invite-service";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: '100%',
    sm: '85%',
    md: '75%', 
    lg: '50%', 
  },
  maxWidth: 'calc(100% - 32px)',
  maxHeight: '90vh', // Make the modal at most 90% of the viewport height
  overflowY: 'auto', // Add scroll on Y-axis if content overflows
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Sidenav({ color, brand, routes, ...rest }) {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const user = authContext.getCurrentUser()

  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);
  const [adminInput, setAdminInput] = useState({ name: '', email: '', phone:'', title:'', msg:''});
  const [adminBtn, setAdminBtn] = useState({text:(user.role == 3)?t("Invite"):t('Add'), isDisabled:false})

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, []);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const handleChild = async (id)=>{
    const result = await authContext.HandleChild(id)
    if(result){
      navigate("/dashboard/analytics");
    }
  }
  const handleLogOut = async () => {
    try {
      await AuthService.logout();
      authContext.logout();
    } catch (err) {
      console.error(err);
      return null;
    }
  };
  const handleAdminChange = (name, value) => {
    setAdminInput(prevInput => ({
      ...prevInput,
      [name]:value,
    }));
  }
  const handleAdminSubmit = async (e) =>{
    e.preventDefault();
    setAdminBtn({text:t('Loading'), isDisabled:true})
    const myData = {
      user:{ id:user.id, name:user.name, role:user.role },
      input:adminInput
    }
    try {
      const response = await inviteService.InviteCollaborator(myData);
      setOpenModal(false)
      if(response.success){
        setAdminInput({ name: '', email: '', phone:'', title:'', msg:''})
        Swal.fire({
          title: t('InvitationSent'),
          icon: "success"
        });
        authContext.setIsAuthenticated(Date.now());
      }else{
        Swal.fire({
          title: t('WentWrong'),
          icon: "error"
        });
      }
      setAdminBtn({text:t('Invite'), isDisabled:false})
    } catch (res) {
      setOpenModal(false)
      Swal.fire({
        title: t('WentWrong'),
        icon: "error"
      });
      // console.log(res.errors[0].detail);
      setAdminBtn({text:t('Invite'), isDisabled:false})
    }
  }
  const handleAddChild = async (e) =>{
    e.preventDefault();
    setAdminBtn({text:t('Loading'), isDisabled:true})
    const myData = {
      id:user.id, name:adminInput.name
    }
    try {
      const response = await inviteService.addChild(myData);
      setOpenModal(false)
      if(response.success){
        setAdminInput({ name: ''})
        authContext.setIsAuthenticated(Date.now())
        Swal.fire({
          title: t('Child Added'),
          icon: "success"
        });
      }else{
        Swal.fire({
          title: t('WentWrong'),
          icon: "error"
        });
      }
      setAdminBtn({text:t('Add'), isDisabled:false})
    } catch (res) {
      setOpenModal(false)
      Swal.fire({
        title: t('WentWrong'),
        icon: "error"
      });
      // console.log(res.errors[0].detail);
      setAdminBtn({text:t('Add'), isDisabled:false})
    }
  }
  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };
  const AddChildBtn =  (
    <MDBox>
        <MDButton
          sx={{
            marginTop:'0.5rem',
            marginBottom:'0.5rem',
            bgcolor: '#4FA3D9 !important',
            color: 'white !important', 
            border: '1px solid #4FA3D9 !important', 
            '&:hover': {
                bgcolor: '#3A91BF !important', 
            },
          }}
          fullWidth
          type="button"
          onClick={handleModalOpen}
        >
          {t('Children')}
        </MDButton>
    </MDBox>
  )
  const AddTeemBtn =  (
    <MDBox>
        <MDButton
          sx={{
            marginTop:'0.5rem',
            marginBottom:'0.5rem',
            bgcolor: '#4FA3D9 !important',
            color: 'white !important', 
            border: '1px solid #4FA3D9 !important', 
            '&:hover': {
                bgcolor: '#3A91BF !important', 
            },
          }}
          fullWidth
          type="button"
          onClick={handleModalOpen}
        >
          {t('Team')}
        </MDButton>
    </MDBox>
  )
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({ name, collapse, route, href, key, type }) => {
      let returnValue;
      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            color={color}
            name={t(name)}
            active={key === itemParentName ? "isParent" : false}
            open={openNestedCollapse === key}
            onClick={({ currentTarget }) =>
              openNestedCollapse === key && currentTarget.classList.contains("MuiListItem-root")
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(key)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        if (name !== "Logout") {
          returnValue = href ? (
            type ? (
              <Can I="edit" this={type}>
                <Link
                  href={href}
                  key={key}
                  target="_blank"
                  rel="noreferrer"
                  sx={{ textDecoration: "none" }}
                >
                  <SidenavItem color={color} name={t(name)} active={key === itemName} />
                </Link>
              </Can>
            ) : (
              <Link
                href={href}
                key={key}
                target="_blank"
                rel="noreferrer"
                sx={{ textDecoration: "none" }}
              >
                <SidenavItem color={color} name={t(name)} active={key === itemName} />
              </Link>
            )
          ) : type ?
          (type == 'link') ?
            (<NavLink to={`orders?teem=${key}&teemName=${name}`} >
              <MDButton
                fullWidth
                type="button"
                sx={{
                  marginBottom:'0.5rem',
                  bgcolor: '#4FA3D9 !important',
                  color: 'white !important', 
                  border: '1px solid #4FA3D9 !important', 
                  '&:hover': {
                      bgcolor: '#3A91BF !important', 
                  },
                }}
              >
                {name}
              </MDButton>
            </NavLink>) : 
            (type == 'button') ? (
              <MDButton
              sx={{
                marginBottom:'0.5rem',
                bgcolor: '#4FA3D9 !important',
                color: 'white !important', 
                border: '1px solid #4FA3D9 !important', 
                '&:hover': {
                    bgcolor: '#3A91BF !important', 
                },
              }}
                fullWidth
                type="button"
                onClick={() => handleChild(key)}
              >
                {name}
              </MDButton>
            ) : (
            <Can I="edit" a={type}>
              <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
                <SidenavItem color={color} name={t(name)} active={key === itemName} />
              </NavLink>
            </Can>
          ) : (
            <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
              <SidenavItem color={color} name={t(name)} active={key === itemName} />
            </NavLink>
          );
        } else {
          returnValue = (
            <MDBox>
              <MDButton
                fullWidth
                variant="gradient"
                color={color}
                type="button"
                onClick={handleLogOut}
              >
                {t("Logout")}
              </MDButton>
            </MDBox>
          );
        }
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

const filteredRoutes = user.role === 4
  ? routes.filter((route) => route.key !== "collaborators")
  : routes;

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = filteredRoutes.map(
    ({ type, name, icon, title, collapse, noCollapse, noArrow, key, href, route }) => {
      let returnValue;

      if (type === "collapse") {
        if (href) {
          returnValue = (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={t(name)}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
                noArrow={noArrow}
              />
            </Link>
          );
        } else if (noCollapse && route) {
          returnValue = (
            (user && user.role > 2 && key != 'prize' && key != 'prize-orders') ?
            <NavLink to={route} key={key}>
              {((user.role == 2 || user.role == 4) && key != "collaborators" && key != "invite_collaborator") ?
              /*(key == 'Chat')?
              <Link 
                onClick={(event) => {
                  event.preventDefault();
                  window.open(route, '_self');
                }}>
              <SidenavCollapse
                name={t(name)}
                icon={icon}
                noCollapse={noCollapse}
                noArrow={noArrow}
                active={key === collapseName}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
              </Link>:*/
              <SidenavCollapse
                name={t(name)}
                icon={icon}
                noCollapse={noCollapse}
                noArrow={noArrow}
                active={key === collapseName}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
               : (user.role > 2 && user.role != 4) &&
              /*(key == 'Chat')?
              <Link 
                onClick={(event) => {
                  event.preventDefault();
                  window.open(route, '_self');
                }}>
              <SidenavCollapse
                name={t(name)}
                icon={icon}
                noCollapse={noCollapse}
                noArrow={noArrow}
                active={key === collapseName}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
              </Link>:*/
              <SidenavCollapse
                name={t(name)}
                icon={icon}
                noCollapse={noCollapse}
                noArrow={noArrow}
                active={key === collapseName}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
              }
            </NavLink> : (user && user.role == 2) &&
            <NavLink to={route} key={key}>
            {(user.role < 3 && key != "collaborators" && key != "invite_collaborator") ?
            /*(key == 'Chat')?
            <Link 
              onClick={(event) => {
                event.preventDefault();
                window.open(route, '_self');
              }}>
            <SidenavCollapse
              name={t(name)}
              icon={icon}
              noCollapse={noCollapse}
              noArrow={noArrow}
              active={key === collapseName}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse></Link>:*/
            <SidenavCollapse
              name={t(name)}
              icon={icon}
              noCollapse={noCollapse}
              noArrow={noArrow}
              active={key === collapseName}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
             : (user.role > 2) &&
            <SidenavCollapse
              name={t(name)}
              icon={icon}
              noCollapse={noCollapse}
              noArrow={noArrow}
              active={key === collapseName}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
            }
          </NavLink>
          );
        } else {
          returnValue = (
            (user && (user.role == 3 || user.role == 4) && key == 'teem') ?
            <>
              <Divider
                key={key}
                light={
                  (!darkMode && !whiteSidenav && !transparentSidenav) ||
                  (darkMode && !transparentSidenav && whiteSidenav)
                }
              />
              <SidenavCollapse
                key={key}
                name={'aa'+(user.role == 3) ? t(name) : t('Children')}
                icon={icon}
                active={key === collapseName}
                open={openCollapse === key}
                onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
              >
                <SidenavList>{(user.role == 3) ? AddTeemBtn : AddChildBtn}</SidenavList>
                <MDBox>{collapse ? renderCollapse(collapse) : null}</MDBox>
              </SidenavCollapse>
            </>:
            (key != 'teem') &&
              <SidenavCollapse
                key={key}
                name={t(name)}
                icon={icon}
                active={key === collapseName}
                open={openCollapse === key}
                onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
          );
        }
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {t(title)}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold", color: "#ffffff" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <MDBox component="img" src={brand} alt="Brand" width="8rem" />}
          <MDBox
            width={"100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List sx={{ height:'70vh', overflowY:'auto', overflowX:'hidden', WebkitOverflowScrolling:'touch' }} className={'scrollbarWidth'}>{renderRoutes}</List>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleModalClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openModal}>
          <Box sx={style} bgcolor={darkMode ? "dark.main":"background.paper"}>
             {/* Close button */}
          <IconButton
            onClick={handleModalClose}
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              color: 'text.secondary',
            }}
          >
            <CloseIcon />
          </IconButton>
            <MDTypography fontWeight="bold" variant="h5" style={{textAlign:'center'}}>{(user.role == 3) ? t("CollaboratorAccessDescription"):t('Add Child')}</MDTypography>
            <Divider/>
            <Grid container spacing={1} component="form" role="form" method="POST" onSubmit={(user.role == 3) ? handleAdminSubmit:handleAddChild}>
              <Grid item xs={12} md={12}>
                <MDBox pt={2}>
                  <MDTypography style={{lineHeight:0}} fontWeight="bold" variant="overline">{(user.role == 3) ? t("EnterRecipientsName"):t("FullName")}</MDTypography>
                  <MDInput style={{width:'100%'}} type="text" required name="name" value={adminInput.name} label={t("Name")} onChange={(e) => handleAdminChange('name', e.target.value)} />
                </MDBox>
                {(user.role == 3) && <>
                <MDBox pt={2}>
                  <MDTypography style={{lineHeight:0}} fontWeight="bold" variant="overline">{t("EnterRecipientsEmailAddress")}</MDTypography>
                  <MDInput style={{width:'100%'}} type="email" required name="email" value={adminInput.email} label={t("Email")} onChange={(e) => handleAdminChange('email', e.target.value)} />
                </MDBox>
                <MDBox pt={2}>
                  <MDTypography style={{lineHeight:0}} fontWeight="bold" variant="overline">{t("EnterPhoneNumber")}</MDTypography>
                  <MuiTelInput style={{width:'100%'}} required defaultCountry={(user.language && (user.language == 'en' || user.language == 'fr' || user.language == 'de')) ? 'NO' : user.language.toUpperCase()} value={adminInput.phone} onlyCountries={['NO']} onChange={(value) => handleAdminChange('phone', value)} />
                </MDBox>
                <MDBox pt={2}>
                  <MDTypography style={{lineHeight:0}} fontWeight="bold" variant="overline">{t("EnterTitle")}</MDTypography>
                  <MDInput style={{width:'100%'}} required type="text" name="title" value={adminInput.title} label={t("Title")} onChange={(e) => handleAdminChange('title', e.target.value)} />
                </MDBox>
                <MDBox pt={2}>
                  <MDTypography style={{lineHeight:0}} fontWeight="bold" variant="overline">{t("EnterMessage")}</MDTypography>
                  <MDInput style={{width:'100%'}} required name="msg" value={adminInput.msg} onChange={(e) => handleAdminChange('msg', e.target.value)} label={t("TypeHere")} multiline rows={5} />
                </MDBox></>}
                <MDBox pt={2} display="flex" justifyContent={'flex-end'}>
                  <MDButton variant="gradient" color="info" disabled = {adminBtn.isDisabled} type="submit">{adminBtn.text}</MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
